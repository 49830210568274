<template>
    <!-- Page Content -->
    <div class="container" id="container">
      <div class="col-lg-12 mb-12 mt-5 text-left">{{ errorMessage }}</div>
    </div>
</template>
<script>
import { confirmRegister } from '@/api/register.js'

export default {
  data() {
    return {
      code: null,
      errorMessage: '',
    }
  },
  created() {
    this.code = this.$route.params.code
    this.register()
  },
  mounted() {
  },
  methods: {
    async register() {
      const this2 = this
      // 本登録API実行
      const res = await confirmRegister({
        code: this.code,
      }).catch((error) => {
        if (error.response && error.response.data && error.response.data.message) {
          this2.errorMessage = error.response.data.message
        }
      })
      if (res && res.result && res.value && res.value.member_code) {
        // localStorageから同意フラグ、事前アンケートの回答データ、フォーム入力データを削除
        localStorage.removeItem('applyFlag')
        localStorage.removeItem('movieFlag')
        localStorage.removeItem('consentFlag')
        localStorage.removeItem('answerInputJson')
        localStorage.removeItem('registerFormDataJson')
        localStorage.removeItem('tempId')
        localStorage.removeItem('currentStatus')
        localStorage.setItem('preAnswerComplete', '1')
        this.$router.push({name: 'PreAnswerComplete'})
      } else if (!this.errorMessage) {
        this.errorMessage = 'サーバの接続に失敗しました。申し訳ありませんが、時間をおいて再度アクセスしてください。'
      }
    },
  },
}
</script>
<style scoped>
div#wallet{
  margin-top: 30px;
}
table{
  width: 40%;
  margin: 0 auto;
}
th{
  background-color: #007db9;
  color: #fff;
}
th, td{
  padding: 5px;
  border: thin solid rgba(0, 0, 0, 0.12);
}
#detail-btn button{
  cursor: pointer;
}
td {
  font-size:small;
}
</style>
<style src="../assets/css/pages/questionnaire.css" scoped></style>
